<template>
  <div v-if="this.$util._isMobile()" class="download_mb_bg" style="height: 100%; overflow: scroll;">
<!--    <div v-if="showBack == 1" style="color: #666666;margin: 20px 0 0 10px; font-size: 16px;" class="el-icon-arrow-left" @click="goBack()">{{ $t('center.go_back') }}</div>-->
    <img style="width: 88px; height: 25px;position: absolute; top:20px; left: 20px;z-index: 2;cursor: pointer" src="../../assets/img/head_logo.png" @click="goBack">

    <div style="margin-top: 90px">
      <span style="display: block; font-weight: 500;font-size: 24px;line-height: 48px;text-align: center;color: #FFFFFF;">让财富近在咫尺</span>
      <span style="display: block; font-weight: 500;font-size: 16px;text-align: center;color: #FFFFFF;">你需要的一切都在UPlus 应用程序中</span>
      <img style="width: 299px; height: 178px;margin: 40px auto 0;display: flex; justify-self: center" src="../../assets/img/download/pc_phone_mb.png">

      <div style="display: flex; justify-content: space-between; width: 80%; margin: 50px auto 0">
        <div>
<!--          <div style="cursor: pointer" @click="downloadIos">-->
<!--            <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/download/ios_btn_icon.png">-->
<!--            <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">直接下载</span>-->
<!--          </div>-->
          <div style="cursor: pointer; margin-top: 10px" @click="downloadPlatform">
            <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/download/ios_btn_icon.png">
            <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">iOS 下载</span>
          </div>
        </div>
        <div>
<!--          <div style="cursor: pointer" @click="downloadAndroid">-->
<!--            <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/download/android_btn_icon.png">-->
<!--            <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">直接下载</span>-->
<!--          </div>-->
          <div style="cursor: pointer; margin-top: 10px" @click="downloadPlatform">
            <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/download/android_btn_icon.png">
            <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">Android 下载</span>
          </div>
        </div>
      </div>
      <p style="margin-top: 20px; color: #FF4D4F; text-align: center; font-weight: 600">注：<span style="color: #FFFFFF; font-weight: 400; font-size: 16px">苹果手机用户需要翻墙使用！</span></p>
    </div>



<!--    <div v-if="!showBack || showBack != 1">-->
<!--      <img :class="device?'down_page_mb_and_no':'down_page_mb_no'" v-if="(token && token != null) && language != 'English' && language != '繁体中文'"  style="display: block; margin: 0 auto" src="../../assets/img/down_page.png">-->
<!--      <img :class="device?'down_page_mb_and_no':'down_page_mb_no'" v-else-if="(token && token != null) && language == '繁体中文'" style="display: block; margin: 0 auto" src="../../assets/img/download/down_page_tw.png">-->
<!--      <img :class="device?'down_page_mb_and_no':'down_page_mb_no'" v-else-if="!token || token == null || language == 'English'" style="display: block; margin: 0 auto" src="../../assets/img/download/down_page_en.png">-->
<!--    </div>-->

<!--    <div style="position: absolute; bottom: 0; width: 100%;  text-align: center; vertical-align: middle">-->
<!--      <div style="background: #EAECEF; width: 100%; height: 1px"></div>-->
<!--      <div style="padding: 30px 0">-->
<!--        <div style="display: inline-block; background: #AB54DB; width: 40%; border-radius: 10px;padding: 10px 0px;" @click="downloadAndroid()">-->
<!--          <img style="display: inline-block;vertical-align: middle; width: 20px" src="../../assets/img/android.png">-->
<!--          <span style="font-size: 14px; color: #FFFFFF; margin-left: 10px;display: inline-block;vertical-align: middle">{{ $t('center.down_android') }}</span>-->
<!--        </div>-->
<!--        <div style="display: inline-block; background: #AB54DB; border-radius: 10px; width: 40%; margin-left: 30px; padding: 10px 0px" @click="downloadIos()">-->
<!--          <img style="display: inline-block;vertical-align: middle; width: 20px" src="../../assets/img/iOS.png">-->
<!--          <span style="font-size: 14px; color: #FFFFFF; margin-left: 10px;display: inline-block;vertical-align: middle">{{ $t('center.coming_online_soon') }}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>

  <div v-else class="pc_bg" style="height: 100%; position: relative">
    <img style="width: 150px; height: 41px;position: absolute; top:55px; left: 78px;z-index: 2;cursor: pointer" src="../../assets/img/head_logo.png" @click="goBack">
    <span style="position: absolute; top: 150px; left: 50%; transform: translate(-50%, 0); font-weight: 500;font-size: 40px;line-height: 48px;text-align: center;color: #FFFFFF;">让财富近在咫尺 你需要的一切都在UPlus 应用程序中</span>
    <div class="down_content" style="position: absolute; top: 50%; transform: translate(0, -50%);z-index: 2">
      <span  style="display: block;font-weight: 400;font-size: 32px;line-height: 38px;color: #FFFFFF;">全自动量化交易机器人</span>
      <span style="display: block;font-weight: 400;font-size: 32px;line-height: 38px;color: #FFFFFF;margin-top: 20px">百万加密货币投资者的信赖</span>
      <div style="margin-top: 86px">
        <img style="width: 139px; height: 139px;" src="../../assets/img/home/download_code.png">
        <div style="display: inline-block; margin-left: 34px;vertical-align: top">
          <div style="background: #EE3EAE; width: 202px; height: 58px;border-radius: 60px; display: flex; align-items: center;cursor: pointer" @click="downloadIos">
            <img style="width: 25px;height: 30px;margin-left: 20px" src="../../assets/img/download/ios_icon.png">
            <span style="font-weight: 400;font-size: 20px;line-height: 24px;color: #FFFFFF;margin-left: 20px">下载 iOS</span>
          </div>
          <div style="background: #FFFFFF; width: 202px; height: 58px;border-radius: 60px; display: flex; align-items: center; margin-top: 20px;cursor: pointer" @click="downloadAndroid">
            <img style="width: 25px;height: 30px;margin-left: 20px" src="../../assets/img/download/android_icon.png">
            <span style="font-weight: 400;font-size: 20px;line-height: 24px;color: #EE3EAE;margin-left: 20px">下载 Android</span>
          </div>
        </div>
        <p style="margin-top: 20px; color: #FF4D4F; text-align: center; font-size: 18px; font-weight: 600">注：苹果手机用户需要翻墙使用！</p>
      </div>
    </div>
    <img style="position: absolute; right: 0; width: 50%;top: 55%; transform: translate(0, -40%)" src="../../assets/img/download/pc_phone.png">
  </div>


</template>

<script>
import screenfull from '../../assets/js/screenfull';
import download from '../../utils/registered_address'
export default {
  name: "download_app",
  data() {
    return {
      showBack: 1,
      token: '1',
      language: '简体中文',
      device: ''
    }
  },

  mounted() {
    this.showBack = this.$route.query.showBack
    this.token = localStorage.getItem('token')
    this.language = localStorage.getItem('language')
    if (this.$route.query.token){
      this.token = this.$route.query.token
    }
    this.device = this.$util.isAndroid()
    console.log(this.device)
    console.log(this.token)
    // this.clickFullscreen()
  },

  methods: {
    clickFullscreen() {
      if (!screenfull.isEnabled) {
        this.$message.error('you browser can not work')
        this.$message({
          message: "you browser can not work",
          type: "warning"
        });
        return false;
      }
      screenfull.toggle();
    },

    // download_android() {
    //   let a = document.createElement('a')
    //   a.href = "https://felton-oss.oss-cn-beijing.aliyuncs.com/file/download/felton-app.apk"
    //   a.click();
    // },
    //
    // download_ios(){
    //   window.open('https://testflight.apple.com/join/yIUwdxMU')
    //   // let a = document.createElement('a')
    //   // a.href = 'https://testflight.apple.com/join/yIUwdxMU'
    //   // a.click();
    // },

    gotoLastPage(){
      if (window.sessionStorage.firstUrl === window.location.href) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    goBack(){
      this.$router.back()
    },

    downloadAndroid(){
      const link = document.createElement("a"); //自己创建的a标签
      link.href = download.download_android_address();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(res.data);
    },
    downloadIos(){
      const link = document.createElement("a"); //自己创建的a标签
      link.href = download.download_ios_address();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(res.data);
    },
    downloadPlatform(){
      const link = document.createElement("a"); //自己创建的a标签
      // link.href = 'https://beta.yizhanapp.cn/Ii8b';
      link.href = download.download_android_address();
      // link.href = 'https://www.uplus100.site/downloadPg';
      link.target='_blank'
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(res.data);
    },
  }

}
</script>

<style scoped>

.down_page_mb {
  width: 375px;
}
.down_page_mb_and {
  width: 375px;
}

.down_page_mb_no {
  width: 100%;
}
.down_page_mb_and_no {
  width: 100%;
}
.pc_bg{
  background-image: url(../../assets/img/download/download_pc_bg.png);
  background-size: 100% 100%;
  /* background-size: cover; */
  /* background-repeat: round; */
  /* background-attachment: fixed; */
  background-position: center;
  width: 100%;
  height: 100%;
  min-width: 1440px;
}
@media screen and (min-width: 1500px){
  .down_content{
    left: 20%;
  }
}
@media screen and (max-width: 1500px){
  .down_content{
    left: 10%;
  }
}
@media screen and (max-width: 428px){
  .down_page_mb {
    width: 84%;
  }

  .down_page_mb_no {
    width: 90%;
  }
}

@media screen and (max-width: 400px){
  .down_page_mb {
    width: 79%;
  }
  .down_page_mb_and {
    width: 90%;
  }

  .down_page_mb_no {
    width: 90%;
  }
  .down_page_mb_and_no {
    width: 90%;
  }
}

@media screen and (max-width: 360px){
  .down_page_mb {
    width: 77%;
  }
  .down_page_mb_and {
    width: 77%;
  }

  .down_page_mb_no {
    width: 77%;
  }
  .down_page_mb_and_no {
    width: 85%;
  }
}

.download_mb_bg{
  background-image: url(../../assets/img/download/download_bg_mb.png);
  background-size: cover;
}

/*@media screen and (max-height: 730px) {*/
/*  .down_page_mb {*/
/*    width: 100%;*/
/*  }*/
/*}*/

</style>
